import clsx from 'clsx';
import { Link } from 'components/link';
import { twMerge } from 'tailwind-merge';
import { ButtonLinkType } from 'types/button';

export const ButtonLink = (props: ButtonLinkType) => {
  return (
    <Link
      href={props.href || ''}
      target={props?.target}
      className={twMerge(
        clsx(
          [
            'relative block h-[50x] w-full rounded-lg bg-blue p-4',
            'text-center text-custom-base font-semibold text-white',
            'hover:bg-blue-hover',
          ],
          props.appearance === 'outline' && [
            'border border-blue bg-white text-blue',
            'hover:border-transparent hover:text-white',
          ]
        ),
        props.className
      )}
      style={props.style}
    >
      <span className='inline-block max-h-[18px]'>{props.children}</span>
    </Link>
  );
};
