'use client';

import { getCookieInClient } from 'utils/cookie';
import { getFromLocalStorage, setToLocalStorage } from 'utils/local-storage';
import { getMiniProfileData } from './user';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { COOKIE_CUSTOMER_TOKEN } from 'utils/constant-cookies';

export const MINI_PROFILE_EXPIRY = 1000 * 60 * 10; // 10 minutes

export async function miniProfileFeeder({
  forceFetch = false,
  language,
  region,
}: {
  forceFetch?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}): Promise<any> {
  let miniProfile = getFromLocalStorage({
    key: 'miniProfile',
  });

  if (forceFetch || !miniProfile) {
    const customerToken = getCookieInClient(COOKIE_CUSTOMER_TOKEN);
    try {
      if (customerToken) {
        miniProfile = await getMiniProfileData(customerToken, language, region);
      } else {
        throw new Error('Customer token is null');
      }

      setToLocalStorage({
        key: 'miniProfile',
        data: miniProfile,
        expires: MINI_PROFILE_EXPIRY,
      });
      return miniProfile;
    } catch (e) {
      return null;
    }
  }

  return miniProfile;
}
