'use client';

// import i18n from 'i18next';
import { useTranslation } from 'app/i18n/client';
import { languages } from 'app/i18n/settings';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import { SelectLanguageType } from 'types/header';
import { LanguageCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function SelectLanguage({
  language,
  region,
  textColor,
}: SelectLanguageType) {
  const pathName = usePathname() || '';
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );

  function changeLanguage(newLanguage: LanguageCodesType) {
    const newLocale = `${newLanguage}-${region.toLowerCase()}`;
    const segments = pathName.split('/');
    if (segments.length > 0) {
      segments[1] = newLocale;
    }
    const updatedUrl = segments.join('/');
    // i18n.changeLanguage(newLocale);
    if (typeof window != 'undefined') {
      window.location.replace(updatedUrl);
    }
  }

  return (
    <div>
      {languages
        .filter((l) => language !== l)
        .map((l, index) => {
          return (
            <span key={l}>
              {index > 0 && ' or '}
              <button
                onClick={() => changeLanguage(l)}
                className={clsx(
                  'min-w-[39px] text-custom-sm font-bold text-blue underline lg:text-custom-base',
                  'hover:underline lg:font-normal lg:text-white lg:no-underline'
                )}
                style={{ color: textColor }}
              >
                {t('change_language')}
              </button>
            </span>
          );
        })}
    </div>
  );
}
