'use client';
import clsx from 'clsx';
import React, { CSSProperties, ReactNode } from 'react';
import { setIsWrapperOpen } from 'redux/features/locator.reducer';
import {
  setReportAnIssueModal,
  setScanAndGoModal,
} from 'redux/features/modals.reducer';
import { useAppDispatch } from 'redux/hooks';

export type LocatorLinkTypes =
  | 'STORE_LOCATOR'
  | 'REPORT_AN_ISSUE'
  | 'SCAN_AND_GO';

export default function LocatorLink({
  children,
  className,
  type,
  style,
}: {
  children: ReactNode;
  className?: string;
  type: LocatorLinkTypes | string;
  style?: CSSProperties;
}) {
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    switch (type) {
      case 'STORE_LOCATOR':
        return dispatch(setIsWrapperOpen(true));
      case 'REPORT_AN_ISSUE':
        return dispatch(setReportAnIssueModal({ isOpen: true }));
      case 'SCAN_AND_GO':
        return dispatch(setScanAndGoModal({ isOpen: true }));
    }
  };

  return (
    <div
      onClick={handleOnClick}
      className={clsx('cursor-pointer', className)}
      style={style}
    >
      {children}
    </div>
  );
}
