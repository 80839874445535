import SecondaryOptionModal from 'components/secondary-option-modal';
import ShippingAddressContent from './shipping-address-content';
import { useTranslation } from 'app/i18n/client';

import { setShippingAddressModal } from 'redux/features/shipping-address-modal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function ShippingAddressModal({
  setShippingAddressModalOpen,
  shippingAddressModalOpen,
  language,
  region,
}: {
  setShippingAddressModalOpen: any;
  shippingAddressModalOpen: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const dispatch = useAppDispatch();
  const editId = useAppSelector((state) => state.customerReducer.editId);
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );

  return (
    <SecondaryOptionModal
      modalId='shipping-address-modal'
      open={shippingAddressModalOpen}
      setOpen={setShippingAddressModalOpen}
      title={ts(
        editId
          ? 'shipping_method_switcher.address_modal.editTitle'
          : 'shipping_method_switcher.address_modal.title'
      )}
      modalWidth='sm:w-[600px]'
      contentClassName='px-0'
      titleContainerClassName='px-8'
      dynamicModalCloseHandler={() => dispatch(setShippingAddressModal(true))}
      reactPortalRemove
      language={language}
      region={region}
    >
      <ShippingAddressContent language={language} region={region} />
    </SecondaryOptionModal>
  );
}
