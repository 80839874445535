'use client';

import { Link } from 'components/link';
import { LinkProps } from 'next/link';
import { CSSProperties, ReactNode } from 'react';
import LocatorLink from './cms/footer/locator-link';
import { LanguageCodesType, RegionCodesType, getUrl } from 'utils';

type LinkItem = {
  link?: string;
  link_type?: string;
  calculated_relative_url?: string;
};

type CustomLinkProps = Omit<LinkProps, 'href'> & {
  className?: string;
  item: LinkItem;
  children?: ReactNode;
  style?: CSSProperties;
  language: LanguageCodesType;
  region: RegionCodesType;
};

export default function ContentfulLink(props: CustomLinkProps) {
  const {
    item = { link: '', link_type: '', calculated_relative_url: '' },
    className,
    children,
    style,
    language,
    region,
  } = props;

  const getRedirectUrl = (item: LinkItem) => {
    if (!item?.link && !item?.calculated_relative_url) {
      return getUrl('/', language, region);
    }

    if (item?.calculated_relative_url) {
      return getUrl(item.calculated_relative_url, language, region);
    }

    if (item?.link_type === 'PLP') {
      return getUrl(`/plp/${item.link}`, language, region);
    } else if (item?.link_type === 'CLP') {
      return getUrl(`${item.link}/clp/`, language, region);
    } else if (item?.link_type === 'PDP') {
      return getUrl(`/pdp/${item.link}`, language, region);
    } else if (
      item?.link_type === 'STATIC' ||
      item?.link_type === 'HP' ||
      item?.link_type === ''
    ) {
      // ??
      return getUrl('/', language, region);
    }

    return getUrl('/', language, region);
  };

  return item.link_type === 'MODULE' ? (
    <LocatorLink className={className} type={item.link!}>
      {children}
    </LocatorLink>
  ) : (
    <Link
      {...props}
      href={item.link_type === 'EXTERNAL' ? item.link! : getRedirectUrl(item)}
      target={item.link_type === 'EXTERNAL' ? '_blank' : '_self'}
      className={className}
      style={style}
    >
      {children}
    </Link>
  );
}
