'use client';

import Image from 'next/image';
import { useTranslation } from 'app/i18n/client';
import clsx from 'clsx';
import { PharmacistLink } from 'components/pharmacist-link';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function CallPharmacist({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );

  return (
    <div className='absolute top-20 z-[52] hidden xl:block ltr:right-8 rtl:left-8'>
      <PharmacistLink
        className='group flex items-center'
        language={language}
        region={region}
      >
        <Image
          src='/assets/images/call-pharmacist.png'
          alt={ts('call_e_pharmacist')}
          width={67}
          height={66}
        />
        <span className='text-custom-base font-semibold text-blue ltr:mt-4 rtl:mt-5'>
          {t('call_e_pharmacist')}
        </span>
        <div
          className={clsx(
            'absolute bottom-0 ltr:-right-8 rtl:-left-8',
            'h-[50px] w-8 from-yellow ltr:bg-gradient-to-l rtl:bg-gradient-to-r',
            'invisible opacity-0 transition-all group-hover:visible group-hover:opacity-100'
          )}
        ></div>
      </PharmacistLink>
    </div>
  );
}
