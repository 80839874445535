import { ILocationData } from 'types/shipping-method-switcher';

/**
 *
 * @param addressComponents This is the address_components from google map response. This is what we distructure to get the details we need
 * @returns {ILocationData} This interface contains all the properties we would like to extract from #addressComponents parameter
 */
export function extractAddressDetails(
  addressComponents: google.maps.GeocoderAddressComponent[]
): ILocationData {
  let streetName = '';
  let regionName = '';
  let regionCode = '';
  let countryCode = '';
  let countryName = '';
  let postalCode = '';
  let city = '';

  addressComponents.forEach((component) => {
    if (component?.types?.includes('route')) {
      streetName = component?.long_name ?? '';
    }
    if (component?.types?.includes('locality')) {
      city = component?.long_name ?? '';
    }
    if (component?.types?.includes('administrative_area_level_1')) {
      regionName = component?.long_name ?? '';
      regionCode = component?.short_name ?? '';
    }
    if (component?.types?.includes('country')) {
      countryCode = component?.short_name ?? '';
      countryName = component?.long_name ?? '';
    }
    if (component?.types?.includes('postal_code')) {
      postalCode = component?.short_name ?? '';
    }
  });

  return {
    streetName,
    regionName,
    regionCode,
    countryCode,
    countryName,
    postalCode,
    city,
  };
}
