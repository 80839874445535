import { ChevronRightIcon } from 'components/icon';
import Image from 'next/image';
import { Link } from 'components/link';
import { Underline } from 'components/buttons/underline';
import { SubMenuType } from 'types/cms/header';
import ContentfulLink from 'components/contentful-link';
export default function Packages({ data, language, region }: SubMenuType) {
  const packages = data;
  if (!packages || !packages.widgets?.length) return null;

  return (
    <>
      <div
        className={'mb-6 flex items-end justify-between'}
        style={{ color: packages.color_theme.header_text_color }}
      >
        <h3
          className='text-lg lg:text-xl'
          style={{ color: packages.color_theme.header_text_color }}
        >
          {packages.header}
        </h3>
        <Underline
          href={packages.cta_link.link || ''}
          color={packages.color_theme?.cta_label_color}
        >
          {packages.cta_link.label}
        </Underline>
      </div>

      <div className='flex justify-between space-x-2 rtl:space-x-reverse'>
        {packages?.widgets[0]?.items?.map((item: any, index: number) => (
          <Link
            key={`package-item-${index}`}
            href={item?.link?.toLowerCase() || ''}
            className='flex flex-col'
          >
            {item.image && (
              <Image
                src={item.image}
                alt={item.label}
                width={0}
                height={0}
                sizes='100vw'
                className='h-[100px] rounded-tl-lg rounded-tr-lg lg:w-full 2xl:w-[150px]'
              />
            )}

            <div className='rounded-bl-lg rounded-br-lg bg-white p-2 lg:w-full 2xl:w-[150px]'>
              <span
                className='block text-xs leading-[15px] text-gray-dark'
                style={{ color: packages.color_theme.item_label_text_color }}
              >
                {item.label}
              </span>
              <div className='mt-2 flex space-x-1 rtl:space-x-reverse'>
                <span
                  className='text-custom-sm font-semibold text-red'
                  style={{
                    color: item.was_price
                      ? packages.color_theme.item_now_price_text_color
                      : packages.color_theme.item_sale_price,
                  }}
                >
                  {item.now_price}
                </span>
                {item.was_price && (
                  <span
                    className='text-xs font-semibold text-gray line-through'
                    style={{
                      color: packages.color_theme.item_was_price_text_color,
                    }}
                  >
                    {item.was_price}
                  </span>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className='mt-8'>
        <span
          className='mb-3 text-xs font-semibold text-gray-dark'
          style={{
            color: packages.widgets[1]?.color_theme?.label_text_color,
          }}
        >
          {packages.widgets[1]?.label}
        </span>

        <ul className='mt-3 space-y-3'>
          {packages?.widgets[1]?.items.map((item: any, index: number) => (
            <li key={`package-secrion-${index}`}>
              <ContentfulLink
                item={item}
                className='flex items-center justify-between rounded-lg bg-white px-2 py-3 text-blue'
                language={language}
                region={region}
              >
                <div className='flex items-center space-x-2 rtl:space-x-reverse'>
                  {item.icon && (
                    <Image
                      src={item.icon}
                      alt={item.label}
                      width={0}
                      height={0}
                      sizes='100%'
                      className='h-6 w-5'
                    />
                  )}

                  <span
                    className='text-custom-base font-semibold'
                    style={{
                      color:
                        packages.widgets[1]?.color_theme
                          ?.item_label_text_color || '',
                    }}
                  >
                    {item.label}
                  </span>
                </div>
                <ChevronRightIcon className='h-4 w-4 rtl:rotate-180' />
              </ContentfulLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
