import AddressPickerWrapper from './maps/address-picker';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function ShippingAddressContent({
  language,
  region,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  return (
    <AddressPickerWrapper language={language} region={region}>
      <AddressPickerWrapper.MapView />
      <AddressPickerWrapper.Search language={language} region={region} />
      <AddressPickerWrapper.PickMyLocation />
      <AddressPickerWrapper.Config language={language} region={region} />
    </AddressPickerWrapper>
  );
}
