import { MenuItemsType } from 'types/cms/header';
import { Underline } from 'components/buttons/underline';
import { useTranslation } from 'app/i18n/client';
import { gtmSetCategoryClick } from 'lib/gtm';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';
import ContentfulLink from 'components/contentful-link';

type SubsecondChildrenType = {
  data: {
    megamenu_item_list: MenuItemsType[];
  };
  language: LanguageCodesType;
  region: RegionCodesType;
};

export default function SubSecondChildren({
  data,
  language,
  region,
}: {
  data: SubsecondChildrenType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );
  if (!data?.megamenu_item_list) return null;
  function handleGtm(item: any) {
    gtmSetCategoryClick(item);
  }
  const ItemHtml = (item: any, index: number) => {
    if (item.label_text_decoration?.includes('UNDERLINED')) {
      return (
        <li key={index}>
          <Underline href={item.link || ''}>{item.label}</Underline>
        </li>
      );
    }
    return (
      <li key={index} onClick={() => handleGtm(item)}>
        <ContentfulLink
          item={item}
          className='flex items-center space-x-2 text-custom-base text-gray-dark hover:text-blue rtl:space-x-reverse'
          language={language}
          region={region}
        >
          <span>{item.label}</span>

          {item.badge_text_color && item.badge_background_color && (
            <span
              style={{
                backgroundColor: item.badge_background_color,
                color: item.badge_text_color,
              }}
              className='rounded-xl bg-error px-2 py-1 text-xs font-bold text-white'
            >
              {item.badge_text || t('new')}
            </span>
          )}
        </ContentfulLink>
      </li>
    );
  };

  return (
    <div className='flex space-x-16 rtl:space-x-reverse'>
      <ul className='space-y-6 text-sm'>
        {data.megamenu_item_list
          .slice(0, 9)
          .map((item: any, index: number) => ItemHtml(item, index))}
      </ul>

      <ul className='space-y-6 text-sm'>
        {data.megamenu_item_list
          .slice(9, data.megamenu_item_list.length)
          .map((item: any, index: number) => ItemHtml(item, index))}
      </ul>
    </div>
  );
}
