'use client';

import Image from 'next/image';
import { MenuIcon, ChevronDownIcon } from 'components/icon';
import ContentfulLink from 'components/contentful-link';
import { MenuItemsType } from 'types/cms/header';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import Services from './services';
import SubChildren from './sub-child';
import clsx from 'clsx';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function MegaMenu({
  data,
  language,
  region,
}: {
  data: MenuItemsType[];
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [showChildMenu, setShowChildMenu] = useState(false);
  const [activeChildMenu, setActiveChildMenu] = useState(null) as any;
  const [activeMenu, setActiveMenu] = useState(null) as any;
  const pathname = usePathname();

  const servicesData = data[2];

  const handleChildMenu = (item: any) => {
    setShowChildMenu(true);
    setActiveChildMenu(item);
    setActiveMenu(item);
  };

  const handleHiddenChildMenu = () => {
    setShowChildMenu(false);
  };

  useEffect(() => {
    setShowChildMenu(false);
  }, [pathname]);

  const ItemClassName = (index: number) => {
    switch (index) {
      case 0:
        return 'ltr:border-r-2 rtl:border-l-2 border-gray-200 ';
      default:
        return '';
    }
  };

  const ItemHtml = (item: any, index: number) => {
    const activeMenuItem = showChildMenu && item.label === activeMenu?.label;

    //if contentful data will be useful, this control will be refactor
    switch (index) {
      case 0:
        return (
          <>
            <MenuIcon />
            <div
              className={clsx(
                'flex items-center space-x-2 text-custom-base font-semibold',
                'cursor-default text-blue hover:text-blue rtl:space-x-reverse'
              )}
            >
              <span className={`${activeMenuItem ? 'text-primary-blue' : ''}`}>
                {item.label}
              </span>
            </div>
            <ChevronDownIcon
              className={`${
                activeMenuItem ? 'rotate-180' : ''
              } h-4 w-4 text-blue hover:text-blue`}
            />
          </>
        );
      case 1:
        return (
          <ContentfulLink
            item={{ link: item.link, link_type: item.link_type }}
            className={clsx(
              'flex items-center space-x-2 text-custom-sm font-semibold',
              'text-red hover:text-red rtl:space-x-reverse'
            )}
            language={language}
            region={region}
          >
            {item.icon && (
              <Image
                src={item.icon}
                alt={item.label}
                width={0}
                height={0}
                sizes='100vw'
                className='h-5 w-4'
              />
            )}
            <span>{item.label}</span>
            {item.megamenu_item_list && (
              <ChevronDownIcon
                className={`${
                  activeMenuItem
                    ? 'rotate-180 text-blue'
                    : `${item.label_text_color_normal}`
                } h-4 w-4 hover:text-blue`}
              />
            )}
          </ContentfulLink>
        );
      default:
        return (
          <>
            {item.link === '#' ? (
              <div
                className={clsx(
                  'flex items-center space-x-2 text-custom-sm font-semibold',
                  'cursor-default text-gray-dark rtl:space-x-reverse'
                )}
              >
                {item.icon && (
                  <Image
                    src={item.icon}
                    alt={item.label}
                    width={0}
                    height={0}
                    sizes='100vw'
                    className='h-5 w-4'
                  />
                )}
                <span
                  style={{ textDecoration: item.labelTextDecoration }}
                  className={`${activeMenuItem ? 'text-primary-blue' : ''}`}
                >
                  {item.label}
                </span>
              </div>
            ) : (
              <ContentfulLink
                item={item}
                className={clsx(
                  'flex items-center space-x-2 text-custom-sm font-semibold',
                  'text-gray-dark rtl:space-x-reverse'
                )}
                language={language}
                region={region}
              >
                {item.icon && (
                  <Image
                    src={item.icon}
                    alt={item.label}
                    width={0}
                    height={0}
                    sizes='100vw'
                    className='h-5 w-4'
                  />
                )}
                <span
                  style={{ textDecoration: item.labelTextDecoration }}
                  className={`${activeMenuItem ? 'text-primary-blue' : ''}`}
                >
                  {item.label}
                </span>
              </ContentfulLink>
            )}

            {item.megamenu_item_list && (
              <ChevronDownIcon
                className={`${
                  activeMenuItem
                    ? 'rotate-180 text-blue'
                    : `${item.label_text_color_normal}`
                } h-4 w-4 hover:text-blue`}
              />
            )}
          </>
        );
    }
  };

  const ActiveParenLink = (item: any) => {
    if (
      item.label === servicesData?.label &&
      activeMenu?.label === servicesData?.label &&
      showChildMenu
    ) {
      return ' relative !text-blue after:absolute after:ltr:left-8 after:rtl:right-8 after:bottom-[-1px] after:z-[99] after:bg-blue after:w-9/12 after:h-0.5';
    }
    return '';
  };

  const ParentItemClassName = (item: any) => {
    return (
      'h-full flex items-center space-x-2 rtl:space-x-reverse ltr:pl-8 py-4 rtl:pr-8 ltr:first:pl-0 ltr:first:pr-8 rtl:first:pr-0 rtl:first:pl-8 ' +
      ActiveParenLink(item)
    );
  };

  return (
    <>
      <div className='relative z-50 h-[50px] w-full bg-white px-8'>
        <ul className='flex h-full items-center text-sm'>
          {data.map((item: any, index: number) => (
            <li
              key={index}
              onMouseEnter={() => handleChildMenu(item)}
              className={`${
                ItemClassName(index) + ParentItemClassName(item)
              } group`}
            >
              {ItemHtml(item, index)}
            </li>
          ))}
        </ul>

        {/* //this line will refactor */}
        {(() => {
          if (activeMenu?.label === servicesData?.label) {
            return (
              <Services
                showMenu={showChildMenu}
                data={
                  activeChildMenu || { labelAlternate: '', megamenu_item: [] }
                }
                language={language}
                region={region}
              />
            );
          } else {
            return (
              <SubChildren
                showMenu={showChildMenu}
                data={activeChildMenu || { megamenu_item: [] }}
                language={language}
                region={region}
              />
            );
          }
        })()}
      </div>

      <div
        onMouseEnter={handleHiddenChildMenu}
        className={`fixed top-0 h-screen w-full bg-black/75 backdrop-blur-sm ${
          !showChildMenu || !activeChildMenu?.megamenu_item_list
            ? 'hidden'
            : 'block'
        }`}
        style={{ zIndex: '49' }}
      ></div>
    </>
  );
}
