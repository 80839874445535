'use client';

import clsx from 'clsx';
import { HeartIcon } from 'components/icon';
import { Link } from 'components/link';
import { myWishlistHref } from 'utils/route-urls';
import { useAppSelector } from 'redux/hooks';
import HydrationWrapper from 'components/hydration-wrapper';

export default function HeaderWishlist({ textColor }: { textColor?: string }) {
  const wishlistStore = useAppSelector((state) => state?.wishlistReducer.data);

  return (
    <Link href={myWishlistHref} className='relative'>
      <HydrationWrapper>
        {wishlistStore?.items?.length > 0 && (
          <span
            className={clsx(
              'absolute -right-4 -top-[7px] h-[18px] w-[30px] rounded-xl text-custom-xs font-semibold',
              'border border-blue bg-white text-blue',
              'flex items-center justify-center'
            )}
          >
            {wishlistStore?.items?.length > 99
              ? '99+'
              : wishlistStore?.items?.length}
          </span>
        )}
      </HydrationWrapper>

      <HeartIcon style={{ fill: textColor }} />
    </Link>
  );
}
