'use client';

import { useEffect, useState } from 'react';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { Button } from 'components/buttons/button';
import ShippingAddressModal from './shipping-addres-modal';
import MobileShippingAddress from './mobile-shipping-address';
import { HomeDeliveryIcon, StorePickupIcon } from 'components/icon';
import clsx from 'clsx';
import { useTranslation } from 'app/i18n/client';
import { useMediaQuery } from 'hooks/use-media-query';
import { useRouter } from 'next/navigation';
import { checkoutHref, loginHref } from 'utils/route-urls';

import {
  setCloseMapInfo,
  setShippingAddressModal,
} from 'redux/features/shipping-address-modal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setMapModal,
  selectShippingMethodModalWithPermission,
} from 'redux/features/address.reducer';
import { setEditId } from 'redux/features/customer';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { handleStorePickup } from './maps/utils/store-pickup';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function ShippingMethodSwitcher({
  storePickupHidden,
  language,
  region,
}: {
  storePickupHidden?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const appDispatch = useAppDispatch();

  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );
  const editAddressCmsContent = useAppSelector(
    (state) => state.addressReducer.editAddressCmsContent
  );

  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  const matches = useMediaQuery('(min-width: 1024px)');
  const [matchesStatus, setMatchesStatus] = useState(false);
  const router = useRouter();

  const { isSelectShippingMethodOpen, isMapModalOpen } = useAppSelector(
    (state) => state.addressReducer
  );
  const { items: basketItems } = useAppSelector((state) => state.basket);

  function handleShippingAddress() {
    appDispatch(setEditId(undefined));
    appDispatch(
      selectShippingMethodModalWithPermission({
        value: false,
        language,
        region,
      })
    );
    appDispatch(setMapModal(true));
    appDispatch(setCloseMapInfo(false));
  }
  function handleLoginRedirect() {
    router.push(`${loginHref}?redirect=${encodeURIComponent(checkoutHref)}`);
    appDispatch(
      selectShippingMethodModalWithPermission({
        value: false,
        language,
        region,
      })
    );
  }

  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);

  return (
    <>
      <SecondaryOptionModal
        modalId='switch-method-modal'
        open={isSelectShippingMethodOpen}
        setOpen={(v: boolean) => {
          appDispatch(
            selectShippingMethodModalWithPermission({
              value: v,
              language,
              region,
            })
          );
        }}
        title={ts('shipping_method_switcher.switch_modal_title')}
        contentClassName='bg-light-gray-200'
        reactPortalRemove
        dynamicModalCloseHandler={() =>
          appDispatch(setShippingAddressModal(false))
        }
        closeButtonClassName={'-me-2'}
        language={language}
        region={region}
      >
        <span className='mt-4 block text-custom-base text-gray-dark lg:mt-0'>
          {t('shipping_method_switcher.products_availability_may_vary')}
        </span>
        <div className='mb-6 mt-4 grid grid-cols-2 gap-x-4'>
          <div
            onClick={() => handleShippingAddress()}
            className={clsx(
              'cursor-pointer rounded-lg border',
              'border-light-gray-300 bg-white py-4 hover:bg-light-gray-100'
            )}
          >
            <div className='flex flex-col items-center'>
              <HomeDeliveryIcon className='mb-6' />
              <span className='mb-2 block text-custom-base font-semibold text-gray-dark'>
                {t('shipping_method_switcher.home_delivery')}
              </span>
              <span className='block text-custom-xs text-gray'>
                {t('shipping_method_switcher.fast')}
              </span>
            </div>
          </div>

          {!storePickupHidden && (
            <div
              onClick={() => {
                handleStorePickup({
                  basketItems,
                  appDispatch,
                  language,
                  region,
                });
              }}
              className={clsx(
                'cursor-pointer rounded-lg border',
                'border-light-gray-300 bg-white py-4 hover:bg-light-gray-100'
              )}
            >
              <div className='flex flex-col items-center'>
                <StorePickupIcon className='mb-6 h-[50px] w-[50px]' />
                <span className='mb-2 block text-custom-base font-semibold text-gray-dark'>
                  {t('shipping_method_switcher.store_pickup')}
                </span>
                <span className='block text-custom-xs text-gray'>
                  {t('shipping_method_switcher.any_time')}
                </span>
              </div>
            </div>
          )}
        </div>
        {!miniProfile && (
          <Button
            onClick={() => handleLoginRedirect()}
            className='!text-custom-base'
          >
            {t('shipping_method_switcher.sign_in')}
          </Button>
        )}
      </SecondaryOptionModal>

      {!matchesStatus ? (
        <MobileShippingAddress
          setShippingAddressModalOpen={(v: boolean) =>
            appDispatch(setMapModal(v))
          }
          shippingAddressModalOpen={isMapModalOpen}
          setShippingSwitchModalOpen={(v: boolean) =>
            appDispatch(
              selectShippingMethodModalWithPermission({
                value: v,
                language,
                region,
              })
            )
          }
          editAddressCmsContent={editAddressCmsContent}
          language={language}
          region={region}
        />
      ) : (
        <ShippingAddressModal
          setShippingAddressModalOpen={(v: boolean) =>
            appDispatch(setMapModal(v))
          }
          shippingAddressModalOpen={isMapModalOpen}
          language={language}
          region={region}
        />
      )}
    </>
  );
}
