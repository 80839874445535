import { useState } from 'react';
import { ChevronRightIcon } from 'components/icon';
import { MenuItemsType, SubMenuType } from 'types/cms/header';

import Packages from './packages';
import SubSecondChildren from './sub-second-child';
import clsx from 'clsx';
import { gtmSetCategoryClick } from 'lib/gtm';
import { LanguageCodesType, RegionCodesType } from 'utils';
import ContentfulLink from 'components/contentful-link';

type ChildMenuType = {
  showMenu: boolean;
  data: {
    megamenu_item_list: MenuItemsType[];
  };
  language: LanguageCodesType;
  region: RegionCodesType;
};

type ActiveChildMenuType = {
  label: string;
  megamenu_item_list: MenuItemsType[];
  submenu_item: SubMenuType;
};

export default function SubChildren({
  showMenu,
  data,
  language,
  region,
}: ChildMenuType) {
  const [activeChildMenu, setActiveChildMenu] = useState(
    null as ActiveChildMenuType | any
  );
  const handleMouseLeave = (item: any) => {
    if (!item.megamenu_item_list) {
      setActiveChildMenu(null);
    }
  };
  function handleGtm(item: any) {
    gtmSetCategoryClick(item);
  }
  if (!data?.megamenu_item_list?.length) return null;
  return (
    <div
      className={`absolute left-0 right-0 top-12 w-full xl:w-[1200px] ${
        !showMenu ? 'hidden' : 'flex'
      }`}
    >
      <div className='z-50 w-[278px] bg-white'>
        <ul>
          {data?.megamenu_item_list.map((item: any, index: number) => (
            <li
              key={index}
              onMouseEnter={() => setActiveChildMenu(item)}
              onMouseLeave={() => handleMouseLeave(item)}
              className={clsx(
                'px-2 py-3 text-gray-dark hover:bg-blue hover:font-semibold hover:text-white ltr:pl-8 rtl:pr-8',
                activeChildMenu?.label === item.label
                  ? 'bg-blue font-semibold text-white'
                  : ''
              )}
              onClick={() => handleGtm(item)}
            >
              <ContentfulLink
                item={{
                  link: item.link,
                  link_type: item.link_type,
                  calculated_relative_url: item.calculated_relative_url,
                }}
                className='flex items-center justify-between'
                language={language}
                region={region}
              >
                <span className='text-custom-base'>{item.label}</span>

                {item.megamenu_item_list && (
                  <ChevronRightIcon className='h-4 w-4 rtl:rotate-180' />
                )}
              </ContentfulLink>
            </li>
          ))}
        </ul>
      </div>

      <div
        className={clsx(
          'z-50 flex w-[920px] justify-between space-x-4 bg-light-gray-200',
          'px-8 py-6 ltr:rounded-br-lg rtl:space-x-reverse rtl:rounded-bl-lg'
        )}
      >
        <div className='w-[62%]'>
          {activeChildMenu?.megamenu_item_list && (
            <>
              <h3 className='mb-6 text-lg lg:text-xl'>
                {activeChildMenu?.label || ''}
              </h3>

              <SubSecondChildren
                data={activeChildMenu}
                language={language}
                region={region}
              />
            </>
          )}
        </div>

        <div className='w-[38%]'>
          <Packages
            data={activeChildMenu?.submenu_item}
            language={language}
            region={region}
          />
        </div>
      </div>
    </div>
  );
}
