'use client';

import clsx from 'clsx';
import { GiftIcon, SmallGiftIcon } from 'components/icon';
import { Link } from 'components/link';
import { useAppSelector } from 'redux/hooks';
import { disableGlobalConfig } from 'utils/disable-global-config';

export default function HeaderLoyalty({ textColor }: { textColor?: string }) {
  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );
  const appSettings = useAppSelector((state) => state.cmsReducer.appSettings);
  const nuhdeekPointEnable = disableGlobalConfig({
    settings: appSettings?.functionalityConfig?.nuhdeekSettings,
  });
  const loyaltyPoints = nuhdeekPointEnable
    ? Number(miniProfile?.nuhdeek_points?.point_value ?? 0)
    : 0;
  function formatNumber(
    point_value: string
  ): string | React.JSX.Element | number {
    const num = Number(point_value);
    if (num >= 1000 && num < 10000) {
      return `${(num / 1000).toFixed(1)}K`;
    } else if (num >= 10000 && num < 1000000) {
      return `${Math.floor(num / 1000)}K`;
    } else if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    } else {
      return num;
    }
  }
  return (
    <Link href='/nuhdeek' className='relative w-auto'>
      {miniProfile && loyaltyPoints > 0 && (
        <>
          <div
            className={clsx(
              'flex items-center space-x-1 overflow-clip rounded-xl bg-black/30 px-2 py-1 text-white lg:hidden rtl:space-x-reverse'
            )}
          >
            <SmallGiftIcon style={{ fill: textColor }} />

            <span className='text-custom-xs'>
              {formatNumber(miniProfile?.nuhdeek_points?.point_value)}
            </span>
          </div>
          {Number(miniProfile?.nuhdeek_points?.point_value) > 0 && (
            <span
              className={clsx(
                'absolute -right-2 -top-[7px] h-[18px] w-auto rounded-[30px] px-1 py-[5px] md:-right-4',
                'border border-blue bg-white',
                'flex items-center justify-center'
              )}
            >
              <span className='text-custom-xs font-bold text-blue'>
                {formatNumber(miniProfile?.nuhdeek_points?.point_value)}
              </span>
            </span>
          )}
        </>
      )}
      <GiftIcon className='hidden lg:block' style={{ fill: textColor }} />
    </Link>
  );
}
