'use client';

import React from 'react';
import { loginHref } from './route-urls';

export async function handleSignout(
  redirectToLogin: boolean,
  event?: React.FormEvent<HTMLFormElement>
) {
  event?.preventDefault();
  // Call the signout action on the server
  const response = await fetch('/api/auth/sign-out', {
    method: 'GET',
  });
  const data = await response.json();

  if (data.success && redirectToLogin) {
    window.location.href = loginHref;
  }
}
