export function getFromLocalStorage({ key }: { key: string }) {
  if (typeof window === 'undefined') return;
  try {
    const storageData = localStorage.getItem(key);
    if (!storageData) {
      return null;
    }
    const temp = JSON.parse(storageData);
    if (temp?.expires < Date.now()) {
      return null;
    }

    return temp.data;
  } catch (e) {
    return null;
  }
}

export function setToLocalStorage({
  key,
  data,
  expires,
}: {
  key: string;
  data: any;
  expires?: number;
}) {
  if (typeof window === 'undefined') return;
  try {
    localStorage.setItem(
      key,
      JSON.stringify({
        data,
        ...(expires && { expires: Date.now() + expires }),
      })
    );
  } catch (ex) {}
}
