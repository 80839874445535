'use client';
import { IAddressData, IDeliveryMode } from 'types/shipping-method-switcher';

const ADDRESS_KEY = 'address_key';

const GUEST_ADDRESS_KEY = 'guest_address_key';
const DELIVERY_MODE_KEY = 'delivery_mode';
const DELIVERY_ADDRESS_KEY = 'delivery_address_id_key';

export class AddressCache {
  static getAddressList(isGuest = false): IAddressData[] {
    if (typeof window === 'undefined') return [];
    try {
      const loc = localStorage.getItem(
        isGuest ? GUEST_ADDRESS_KEY : ADDRESS_KEY
      );
      if (!loc) return [];
      return (JSON.parse(loc) as IAddressData[]) ?? [];
    } catch (ex) {
      return [];
    }
  }

  static updateList(addresses: IAddressData[], isGuest = false) {
    if (typeof window === 'undefined') return;
    const key = isGuest ? GUEST_ADDRESS_KEY : ADDRESS_KEY;
    if (addresses.length == 0) {
      return localStorage.removeItem(key);
    }
    localStorage.setItem(key, JSON.stringify(addresses));
  }

  static addAddress(address: IAddressData) {
    if (typeof window === 'undefined') return;
    let existingAddress = this.getAddressList();
    const index = existingAddress.findIndex(
      (add) => add.additional?.place_id === address.additional?.place_id
    );
    if (index == -1) {
      existingAddress.push(address);
      existingAddress = existingAddress.reverse();
    } else {
      existingAddress[index] = address;
    }

    localStorage.setItem(ADDRESS_KEY, JSON.stringify(existingAddress));
  }

  static getDeliveryMode(): IDeliveryMode {
    if (typeof window === 'undefined')
      return { isGuest: true, mode: 'delivery' };
    try {
      const mode = localStorage.getItem(DELIVERY_MODE_KEY);
      if (!mode) return { isGuest: true, mode: 'delivery' };
      return JSON.parse(mode) as IDeliveryMode;
    } catch (ex) {
      return { isGuest: true, mode: 'delivery' };
    }
  }

  static setDeliveryMode(mode: IDeliveryMode) {
    if (typeof window === 'undefined') return;
    localStorage.setItem(DELIVERY_MODE_KEY, JSON.stringify(mode));
  }

  static setDeliveryAddressId(id?: string) {
    if (typeof window === 'undefined') return;
    if (id) {
      localStorage.setItem(DELIVERY_ADDRESS_KEY, id);
    } else {
      localStorage.removeItem(DELIVERY_ADDRESS_KEY);
    }
  }

  static getDeliveryAddressId(): string | undefined {
    if (typeof window === 'undefined') return undefined;
    try {
      return localStorage.getItem(DELIVERY_ADDRESS_KEY) ?? undefined;
    } catch (ex) {
      return undefined;
    }
  }
}
