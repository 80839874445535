import { GoBackIcon } from 'components/icon';
import ShippingAddressContent from './shipping-address-content';
import { useTranslation } from 'app/i18n/client';
import { useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import { EditAddressCmsType } from 'redux/features/address.reducer';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function MobileShippingAddress({
  setShippingAddressModalOpen,
  shippingAddressModalOpen,
  setShippingSwitchModalOpen,
  editAddressCmsContent,
  language,
  region,
}: {
  setShippingAddressModalOpen: any;
  shippingAddressModalOpen: boolean;
  setShippingSwitchModalOpen: any;
  editAddressCmsContent: EditAddressCmsType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const editId = useAppSelector((state) => state.customerReducer.editId);
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'common'
  );
  function handleGoBack() {
    setShippingAddressModalOpen(false);
    if (!editId) {
      setShippingSwitchModalOpen(true);
    }
  }
  useEffect(() => {
    document.body.style.overflow = shippingAddressModalOpen ? 'hidden' : '';
  }, [shippingAddressModalOpen]);
  const isEdit = editId !== undefined;
  return (
    <div
      className={`fixed bottom-0 z-[61] h-dvh w-full overflow-y-auto bg-light-gray-200 transition-all ${
        shippingAddressModalOpen
          ? 'ltr:right-0 rtl:left-0'
          : 'ltr:-right-[4000px] rtl:-left-[4000px]'
      }`}
    >
      <div className='w-full bg-white p-4 lg:hidden'>
        <div className='flex items-center justify-between rtl:flex-row-reverse'>
          <GoBackIcon
            onClick={() => handleGoBack()}
            className='has-color fill-current text-blue rtl:rotate-180'
          />
          <h1 className='line-clamp-1 px-3 text-xl'>
            {editAddressCmsContent?.header?.[language] !== '' &&
            editAddressCmsContent?.header?.[language] !== undefined
              ? editAddressCmsContent?.header?.[language]
              : t(
                  isEdit
                    ? 'shipping_method_switcher.address_modal.editTitle'
                    : 'shipping_method_switcher.address_modal.title'
                )}
          </h1>
          <div></div>
        </div>
      </div>
      <ShippingAddressContent language={language} region={region} />
    </div>
  );
}
