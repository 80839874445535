import { createSlice } from '@reduxjs/toolkit';
interface InitalStateType {
  globalDeliveryWarningModal: GlobalDeliveryModalType;
  reportAnIssueModal: { isOpen: boolean };
  scanAndGoModal: { isOpen: boolean };
  toastAnimation: ToastAnimationType;
}
type ToastAnimationType = {
  slideDownIsOpen: boolean;
  wishlistTransferStatus: {
    isLoading: boolean;
    isBulkOperation: boolean;
    itemId: number;
  };
};

type GlobalDeliveryModalType = {
  type: 'only-home-delivery' | 'confirm';
  isOpen: boolean;
};

export const toastAnimationInitialState = {
  slideDownIsOpen: false,
  wishlistTransferStatus: {
    isLoading: false,
    isBulkOperation: false,
    itemId: 0,
  },
};

const initialState: InitalStateType = {
  globalDeliveryWarningModal: {
    type: 'only-home-delivery',
    isOpen: false,
  },
  reportAnIssueModal: {
    isOpen: false,
  },
  scanAndGoModal: {
    isOpen: false,
  },
  toastAnimation: toastAnimationInitialState,
};
export const Modals = createSlice({
  name: 'modalReducer',
  initialState,
  reducers: {
    setGlobalDeliveryWarningModal: (
      state,
      action: { payload: GlobalDeliveryModalType }
    ) => {
      state.globalDeliveryWarningModal = action.payload;
    },
    closeGlobalDeliveryWarningModal: (state) => {
      state.globalDeliveryWarningModal = {
        isOpen: false,
        type: 'only-home-delivery',
      };
    },
    setReportAnIssueModal: (
      state,
      action: { payload: { isOpen: boolean } }
    ) => {
      state.reportAnIssueModal = action.payload;
    },
    setScanAndGoModal: (state, action: { payload: { isOpen: boolean } }) => {
      state.scanAndGoModal = action.payload;
    },
    openToastAnimationCompleted: (state) => {
      state.toastAnimation.slideDownIsOpen = true;
      state.toastAnimation.wishlistTransferStatus.isLoading = false;
    },
    setToastAnimation: (state, action: { payload: ToastAnimationType }) => {
      state.toastAnimation = action.payload;
    },
    resetToastAnimation: (state) => {
      state.toastAnimation = toastAnimationInitialState;
    },
  },
});
export const {
  setGlobalDeliveryWarningModal,
  closeGlobalDeliveryWarningModal,
  setReportAnIssueModal,
  setScanAndGoModal,
  setToastAnimation,
  resetToastAnimation,
  openToastAnimationCompleted,
} = Modals.actions;
export default Modals.reducer;
