'use client';

import { UserIcon, ChevronDownIcon, ChevronRightIcon } from 'components/icon';
import { useTranslation } from 'app/i18n/client';
import clsx from 'clsx';
import { Link } from 'components/link';
import { useEffect, useRef, useState } from 'react';
import { loginHref } from 'utils/route-urls';
import { handleSignout } from 'utils/sign-out-handler';

import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { useAppSelector } from 'redux/hooks';

export default function LoginButton({
  textColor,
  language,
  region,
}: {
  textColor?: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const refDropDown = useRef(null);
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );
  const [isMiniProfileExist, setIsMiniProfileExist] = useState(false);

  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );
  useEffect(() => {
    if (miniProfile) {
      setIsMiniProfileExist(true);
    } else {
      setIsMiniProfileExist(false);
    }
  }, [miniProfile]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeOpenMenu = (event: any) => {
    if (
      refDropDown.current &&
      isOpen &&
      !(refDropDown.current as HTMLElement).contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeOpenMenu);
  }, [closeOpenMenu]);

  return (
    <>
      {isMiniProfileExist && (
        <div
          className='relative'
          ref={refDropDown}
          style={{ color: textColor }}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={clsx(
              'relative w-full cursor-pointer rounded-md lg:w-36',
              'border border-primary-blue px-3 py-3 text-primary-blue lg:border-white lg:py-2 lg:text-white',
              'flex items-center justify-center bg-white lg:bg-transparent'
            )}
          >
            <div className='flex items-center justify-between'>
              <div className='flex items-center space-x-2 rtl:space-x-reverse'>
                <div className='absolute lg:relative ltr:left-4 ltr:lg:left-0 rtl:right-4 rtl:lg:right-0'>
                  <UserIcon className='has-color fill-current text-blue' />
                </div>
                <div className='hidden w-20 truncate text-custom-sm font-semibold lg:flex'>
                  <span>{t('user.hi')},</span>
                  <span className='truncate'>
                    &nbsp;{miniProfile?.firstname}
                  </span>
                </div>

                <div className='flex w-20 truncate text-custom-sm font-semibold lg:hidden'>
                  <span className='truncate'>{t('user.my_account')}</span>
                </div>
                <ChevronRightIcon
                  className={
                    'has-color absolute h-5 w-5 text-gray-700 lg:hidden ltr:right-4 rtl:left-4 rtl:rotate-180'
                  }
                />
              </div>
              <ChevronDownIcon
                className={`${
                  isOpen ? 'rotate-180' : 'rotate-0'
                } hidden h-4 w-4 lg:block`}
              />
            </div>
          </div>

          <div
            className={`${
              isOpen ? 'opacity-1 visible' : 'invisible opacity-0'
            } absolute left-0 z-[99] w-full rounded-lg bg-white shadow-2xl transition-all duration-500 ease-in-out lg:-left-[58px] lg:w-[200px]`}
          >
            <div className='absolute -top-2 inline-block w-6 overflow-hidden ltr:right-0 rtl:right-28'>
              <div className='h-2 w-2 origin-bottom-left rotate-45 transform bg-white'></div>
            </div>
            <ul className='space-y-2 divide-y p-4'>
              <li>
                <Link
                  onClick={() => setIsOpen(false)}
                  href='/account'
                  className='block w-full text-custom-base text-gray-dark hover:text-primary-blue'
                >
                  {t('user.my_account')}
                </Link>
              </li>
              <li className='pb-2 pt-4'>
                <Link
                  onClick={() => setIsOpen(false)}
                  href='/account/my-orders'
                  className='block w-full text-custom-base text-gray-dark hover:text-primary-blue'
                >
                  {t('user.my_orders')}
                </Link>
              </li>
              <li className='pb-2 pt-4'>
                <Link
                  onClick={() => setIsOpen(false)}
                  href='/account/re-order'
                  className='block w-full text-custom-base text-gray-dark hover:text-primary-blue'
                >
                  {t('user.buy_again')}
                </Link>
              </li>
              <li className='pb-2 pt-4'>
                <form
                  onSubmit={(e) => {
                    handleSignout(true, e);
                  }}
                >
                  <button
                    type='submit'
                    onClick={() => setIsOpen(false)}
                    className='block w-full text-custom-base text-gray-dark hover:text-primary-blue ltr:text-left rtl:text-right'
                  >
                    {t('user.logout')}
                  </button>
                </form>
              </li>
            </ul>
          </div>
        </div>
      )}

      {!isMiniProfileExist && (
        <Link
          style={{
            color: textColor,
            borderColor: textColor,
          }}
          href={loginHref}
          className={clsx(
            'relative w-full rounded-md border border-primary-blue shadow-standart lg:w-36 lg:border-white',
            'px-3 py-3 text-primary-blue lg:py-2 lg:text-white',
            'flex items-center justify-center bg-white lg:bg-transparent'
          )}
        >
          <span className='flex items-center justify-center text-custom-sm font-semibold hover:underline'>
            {t('login_register')}
          </span>
          <div className='absolute h-6 w-6 lg:relative lg:hidden ltr:left-4 rtl:right-4'>
            <UserIcon className='has-color fill-current text-blue lg:text-white' />
          </div>
        </Link>
      )}
    </>
  );
}
