'use client';
import { CartIcon } from 'components/icon';
import clsx from 'clsx';
import { Link } from 'components/link';
import { useAppSelector } from 'redux/hooks';
import HydrationWrapper from 'components/hydration-wrapper';

export default function Cart({ textColor }: { textColor?: string }) {
  const totalQuantity = useAppSelector((state) => state.basket.totalQuantity);
  return (
    <Link href={'/my-cart'} className='relative'>
      <CartIcon className='h-6 w-6' style={{ fill: textColor }} />
      <HydrationWrapper>
        {totalQuantity > 0 && (
          <div
            className={clsx(
              'absolute -top-[5px] end-[3px] -mr-1.5 h-[18px] w-[18px] rounded-full',
              'border border-primary-blue bg-primary-red text-[11px] font-bold text-white',
              'flex items-center justify-center'
            )}
            style={{ color: textColor }}
          >
            {totalQuantity}
          </div>
        )}
      </HydrationWrapper>
    </Link>
  );
}
