import Image from 'next/image';
import { MenuItemsType, SubMenuType } from 'types/cms/header';
import Packages from './packages';
import clsx from 'clsx';
import { LanguageCodesType, RegionCodesType } from 'utils';
import ContentfulLink from 'components/contentful-link';

type ChildMenuType = {
  showMenu: boolean;
  data: {
    label_alternate: string;
    megamenu_item_list: MenuItemsType[];
    submenu_item?: SubMenuType | any;
    icon: string;
  };
  language: LanguageCodesType;
  region: RegionCodesType;
};

export default function SubChildren({
  showMenu,
  data,
  language,
  region,
}: ChildMenuType) {
  if (!data.megamenu_item_list?.length) return null;

  return (
    <div
      className={`absolute top-12 w-[650px] xl:w-[920px] ltr:left-[22rem] rtl:right-[20rem] ${
        !showMenu ? 'hidden' : 'flex'
      }`}
    >
      <div className='z-50 w-5/12 bg-light-gray-200 xl:w-4/12 ltr:rounded-bl-lg rtl:rounded-br-lg'>
        <ul className='py-6 ltr:pl-7 rtl:pr-7'>
          <li className='mb-6 flex items-center space-x-2 rtl:space-x-reverse'>
            {data.icon && (
              <Image
                src={data.icon}
                alt={data.label_alternate}
                width={0}
                height={0}
                sizes='100vw'
                className='h-[22px] w-[18px]'
              />
            )}
            <span className='text-lg text-black xl:text-xl'>
              {data.label_alternate}
            </span>
          </li>

          {data?.megamenu_item_list.map((item: any, index: number) => (
            <li
              key={index}
              className='mt-3 flex items-center space-x-2 rtl:space-x-reverse'
            >
              {item.icon && (
                <Image
                  src={item.icon}
                  alt={item.label}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-8 w-8'
                />
              )}

              <ContentfulLink
                item={item}
                className='group flex items-center justify-between text-sm xl:text-base'
                language={language}
                region={region}
              >
                <span className='text-custom-base text-gray-dark group-hover:text-blue'>
                  {item.label}
                </span>
              </ContentfulLink>
            </li>
          ))}
        </ul>
      </div>

      <div
        className={clsx(
          'z-50 flex w-7/12 justify-between bg-light-gray-200 xl:w-8/12',
          'space-x-4 px-8 py-6 ltr:rounded-br-lg rtl:space-x-reverse rtl:rounded-bl-lg'
        )}
      >
        <div className='w-1/12 xl:w-5/12'></div>
        <div className='w-11/12 xl:w-7/12'>
          <Packages
            data={data?.submenu_item}
            language={language}
            region={region}
          />
        </div>
      </div>
    </div>
  );
}
