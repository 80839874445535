import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AddressCache } from 'components/shipping-method-switcher/maps/utils';
import { MINI_PROFILE_EXPIRY } from 'lib/nahdi-middleware/account/customer-feeders';
import { CustomerMiniProfile, GuestMiniProfile } from 'types/customer';
import { IAddressData, IDeliveryMode } from 'types/shipping-method-switcher';
import { setToLocalStorage } from 'utils/local-storage';

type CustomerState = {
  myOrdersOrigin: string;
  miniProfile: CustomerMiniProfile | null;
  addressList: IAddressData[];
  editId?: string;
  guest?: GuestMiniProfile;
  deliveryMode: IDeliveryMode;
  selectedAddressId?: string;
};

const initialState: CustomerState = {
  miniProfile: null,
  addressList: AddressCache.getAddressList(),
  deliveryMode: AddressCache.getDeliveryMode(),
  selectedAddressId: AddressCache.getDeliveryAddressId(),
  myOrdersOrigin: 'ONLINE_ORDERS',
};

const initialDefaultAddress = {
  type: '',
  firstname: '',
  lastname: '',
  street: null,
  city: '',
  region: {
    region_id: 0,
    region_code: '',
    region: '',
  },
  postcode: '',
  country_code: '',
  default_shipping: false,
  telephone: '',
  vat_id: '',
  latitude: null,
  longitude: null,
};

export const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setMiniProfile: (
      state,
      action: { payload: CustomerMiniProfile | null }
    ) => {
      if (action.payload) {
        state.miniProfile = action.payload;
        state.guest = undefined;
      } else {
        state.miniProfile = null;
      }
    },
    setAddressList: (
      state: CustomerState,
      action: PayloadAction<IAddressData[]>
    ) => {
      state.addressList = action.payload;
      AddressCache.updateList(state.addressList);

      const defaultIndex = state.addressList.findIndex(
        (add) => add.default_shipping
      );
      if (
        defaultIndex != -1 &&
        state.addressList?.[defaultIndex] &&
        state.miniProfile?.default_address &&
        state.miniProfile?.default_address?.id !=
          state.addressList[defaultIndex]?.id
      ) {
        state.miniProfile.default_address = state.addressList?.[
          defaultIndex
        ] as never;
      }
    },

    setGuestProfile: (
      state: CustomerState,
      action: PayloadAction<IAddressData>
    ) => {
      state.guest = {
        guestDefaultAddress: action.payload,
        addressList: [action.payload],
      };
      AddressCache.updateList([action.payload], true);
      const mode: IDeliveryMode = {
        isGuest: true,
        mode: 'delivery',
      };
      state.deliveryMode = mode;
      AddressCache.setDeliveryMode(mode);
    },
    deleteAddress: (state: CustomerState, action: PayloadAction<string>) => {
      state.addressList = state.addressList.filter(
        (address) => address.id != action.payload
      );
      if (
        state.miniProfile?.default_address?.id?.toString() == action.payload
      ) {
        state.miniProfile.default_address = initialDefaultAddress as any;
      }
      if (state.selectedAddressId == action.payload) {
        state.selectedAddressId = undefined;
        AddressCache.setDeliveryAddressId(undefined);
      }
      AddressCache.updateList(state.addressList);
    },

    setSelectedAddressId(
      state: CustomerState,
      action: PayloadAction<string | undefined>
    ) {
      state.selectedAddressId = action.payload;
      AddressCache.setDeliveryAddressId(action.payload);
      const mode: IDeliveryMode = {
        isGuest: false,
        mode: action.payload ? 'delivery' : state.deliveryMode.mode,
      };
      state.deliveryMode = mode;
      AddressCache.setDeliveryMode(mode);
    },

    deleteGuestAddress: (state: CustomerState) => {
      state.guest = {
        guestDefaultAddress: null,
        addressList: [],
      };
      AddressCache.updateList([], true);
    },
    setEditId: (
      state: CustomerState,
      action: PayloadAction<string | undefined>
    ) => {
      state.editId = action.payload;
    },
    updateAddress: (
      state: CustomerState,
      action: PayloadAction<IAddressData>
    ) => {
      if (action.payload.default_shipping) {
        state.addressList.forEach((add) => {
          add.default_billing = false;
          add.default_shipping = false;
        });
      }

      const index = state.addressList.findIndex(
        (add) => add.id == action.payload.id
      );
      if (index != -1) {
        state.addressList[index] = action.payload;
      }

      if (state?.miniProfile?.default_address?.id == action.payload.id) {
        state!.miniProfile!.default_address = { ...action.payload } as any;
      }

      state.selectedAddressId = action.payload.id?.toString();
      AddressCache.setDeliveryAddressId(action.payload.id?.toString());
      AddressCache.updateList(state.addressList);
    },
    addAddress: (state: CustomerState, action: PayloadAction<IAddressData>) => {
      if (action.payload.default_shipping) {
        state.addressList.forEach((add) => {
          add.default_billing = false;
          add.default_shipping = false;
        });
      }
      state.addressList.push(action.payload);

      if (
        (action.payload.default_shipping &&
          !state.miniProfile?.default_address.id) ||
        state?.miniProfile?.default_address?.id == action.payload.id
      ) {
        state!.miniProfile!.default_address = { ...action.payload } as any;
      }
      const mode: IDeliveryMode = {
        isGuest: false,
        mode: 'delivery',
      };
      state.deliveryMode = mode;
      AddressCache.setDeliveryMode(mode);
      state.selectedAddressId = action.payload.id?.toString();
      AddressCache.setDeliveryAddressId(action.payload.id?.toString());
      AddressCache.updateList(state.addressList);
    },
    initDefaultGuestAddress: (state: CustomerState) => {
      if (!state.miniProfile) {
        const guestAddresses = AddressCache.getAddressList(true);
        if (guestAddresses.length > 0 && guestAddresses?.[0]) {
          state.guest = {
            addressList: guestAddresses,
            guestDefaultAddress: guestAddresses[0],
          };
        }
      }
    },
    updateDeliveryMode: (
      state: CustomerState,
      { payload }: PayloadAction<'pickup' | 'delivery'>
    ) => {
      let mode: IDeliveryMode;
      if (state.miniProfile) {
        mode = { isGuest: false, mode: payload };
      } else {
        mode = { isGuest: true, mode: payload };
      }
      state.deliveryMode = mode;
      AddressCache.setDeliveryMode(mode);
    },
    updateCachedProfileOnGuestAccountTransfer: (state: CustomerState) => {
      setToLocalStorage({
        key: 'miniProfile',
        data: state.miniProfile,
        expires: MINI_PROFILE_EXPIRY,
      });
    },
    setMyOrdersOrigin: (
      state: CustomerState,
      action: PayloadAction<string>
    ) => {
      state.myOrdersOrigin = action.payload;
    },
  },
});

export const {
  setMiniProfile,
  setAddressList,
  setGuestProfile,
  deleteGuestAddress,
  setEditId,
  deleteAddress,
  updateAddress,
  addAddress,
  initDefaultGuestAddress,
  updateDeliveryMode,
  setSelectedAddressId,
  updateCachedProfileOnGuestAccountTransfer,
  setMyOrdersOrigin,
} = customer.actions;
export default customer.reducer;
