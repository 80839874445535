'use client';

import { getPharmacistUrl, LanguageCodesType, RegionCodesType } from 'utils';
import { Link } from './link';
import { ReactNode, useEffect, useState } from 'react';
import { loginHref } from 'utils/route-urls';
import { ButtonLink } from './buttons/button-link';
import { useAppSelector } from 'redux/hooks';

export const PharmacistLink = ({
  children,
  className,
  isButton = false,
  language,
  region,
  ...rest
}: {
  children: ReactNode;
  className?: string;
  isButton?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) => {
  const [pharmacistUrl, setPharmacistUrl] = useState<string>();

  const functionalityConfig = useAppSelector(
    (state) => state.cmsReducer.appSettings.functionalityConfig
  );

  useEffect(() => {
    const getUrl = async () => {
      const url = await getPharmacistUrl(functionalityConfig);
      setPharmacistUrl(url);
    };
    getUrl();
  }, [functionalityConfig, language, region]);

  if (isButton) {
    return (
      <ButtonLink
        href={pharmacistUrl || loginHref}
        target={pharmacistUrl ? '_blank' : '_self'}
        className={className}
        {...rest}
      >
        {children}
      </ButtonLink>
    );
  }

  return (
    <Link
      href={pharmacistUrl || loginHref}
      target={pharmacistUrl ? '_blank' : '_self'}
      className={className}
      {...rest}
    >
      {children}
    </Link>
  );
};
