import { createSlice } from '@reduxjs/toolkit';

type LocatorState = {
  isWrapperOpen: boolean;
};

const initialState: LocatorState = {
  isWrapperOpen: false,
};

export const locator = createSlice({
  name: 'locator',
  initialState,
  reducers: {
    setIsWrapperOpen: (state, action: { payload: boolean }) => {
      state.isWrapperOpen = action.payload;
    },
  },
});

export const { setIsWrapperOpen } = locator.actions;

export default locator.reducer;
