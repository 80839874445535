'use client';

import { useEffect, useState } from 'react';
import { useMediaQuery } from 'hooks/use-media-query';
import { CloseIcon } from 'components/icon';
import clsx from 'clsx';
import { ModalStyleType } from 'types/modal';
import Image from 'next/image';
import { RemoveScroll } from 'react-remove-scroll';

export type MobileModalType = {
  children?: React.ReactNode;
  containerClassName?: string;
  isOpen: boolean;
  setIsOpen: any;
  tabletShow?: boolean;
  title?: string | undefined;
  style: 'full' | 'bottom';
  dynamicModalCloseHandler?: any;
  backgroundColor?: string;
  backgroundImage?: string;
  closeButtonImage?: string;
  contentClassName?: string;
  closeButtonClassName?: string;
  backgroundBlurClassName?: string;
  hideCloseButton?: boolean;
  hideCloseButtonOnMobile?: boolean;
  titleColor?: string;
  titleContainerClassName?: string;
  titleClassName?: string;
};

export default function MobileModal({
  children,
  containerClassName,
  isOpen,
  setIsOpen,
  tabletShow = true,
  title,
  style = 'full',
  dynamicModalCloseHandler,
  backgroundColor,
  backgroundImage,
  closeButtonImage,
  contentClassName,
  closeButtonClassName,
  backgroundBlurClassName,
  hideCloseButton,
  hideCloseButtonOnMobile,
  titleColor,
  titleContainerClassName,
  titleClassName,
}: MobileModalType) {
  const tabletWithMatches = tabletShow
    ? '(min-width: 991px)'
    : '(min-width: 480px)';
  const matches = useMediaQuery(tabletWithMatches);
  const [matchesStatus, setMatchesStatus] = useState(false);

  function handleCloseModal() {
    setIsOpen(false);
    if (dynamicModalCloseHandler) {
      dynamicModalCloseHandler();
    }
  }
  const [delayedOpen, setDelayedOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setDelayedOpen(true);
      }, 100);
      return () => clearTimeout(timer);
    } else {
      setDelayedOpen(false);
    }
  }, [isOpen]);
  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);

  if (!isOpen && !delayedOpen) return null;

  const CloseButton = () => (
    <Image
      src={closeButtonImage as string}
      alt='Close'
      height={20}
      width={20}
      className='h-5 w-5 cursor-pointer'
    />
  );

  if (matchesStatus) return null;

  const containerStyle: ModalStyleType = {};

  if (backgroundColor) {
    containerStyle.backgroundColor = backgroundColor;
  }
  if (backgroundImage) {
    containerStyle.backgroundImage = `url('${backgroundImage}')`;
  }

  return (
    <RemoveScroll>
      <div
        className={clsx(
          delayedOpen ? 'opacity-1 visible' : 'invisible opacity-0',
          'fixed left-0 right-0 top-0 z-[99] h-screen w-full bg-black/75 backdrop-blur-sm transition-opacity duration-500',
          backgroundBlurClassName
        )}
        onClick={handleCloseModal}
      ></div>
      <div
        className={clsx(
          containerClassName,
          style === 'bottom' || style === 'full'
            ? 'fixed bottom-0'
            : 'fixed top-0',
          style === 'full'
            ? 'max-h-svh min-h-screen'
            : 'max-h-[90svh] rounded-t-[22px]',
          'fixed left-0 right-0 z-[100] w-full transform overflow-auto bg-white bg-cover transition-transform duration-500 ease-in-out',
          delayedOpen ? 'translate-y-0' : 'translate-y-full'
        )}
        style={containerStyle}
      >
        <div className={clsx(['px-4 py-6 lg:py-4', contentClassName])}>
          <div
            className={clsx([
              'flex items-center justify-between',
              titleContainerClassName,
            ])}
            style={{ color: titleColor }}
          >
            <span
              className={clsx([
                'text-xl tracking-tight lg:text-custom-lg',
                titleClassName,
              ])}
            >
              {title}
            </span>
            <span
              className={clsx(
                'absolute end-6 top-6 z-[101] flex h-5 w-5',
                closeButtonClassName
              )}
            >
              {!hideCloseButton &&
                !hideCloseButtonOnMobile &&
                (closeButtonImage ? (
                  <span onClick={handleCloseModal}>
                    <CloseButton />
                  </span>
                ) : (
                  <CloseIcon
                    className='h-7 w-7 cursor-pointer'
                    onClick={handleCloseModal}
                  />
                ))}
            </span>
          </div>
          {children}
        </div>
      </div>
    </RemoveScroll>
  );
}
