import clsx from 'clsx';
import cn from 'clsx';
import { ErrorMessage, Field } from 'formik';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { InputPropsType } from 'types/input';
import { PhoneInput } from './phone-input';
import { CustomCSSProperties } from 'types/prop-types';

const Input = forwardRef<HTMLInputElement, InputPropsType>(
  ({ variant, ...props }, ref) => {
    const styles = {
      backgroundColor: props.readOnly
        ? props?.inputStyles?.input_locked_background_color
        : props?.inputStyles?.input_background_color,
      color: props.readOnly
        ? props?.inputStyles?.input_locked_text_color
        : props?.inputStyles?.input_text_color,
      placeholder: props?.inputStyles?.input_placeholder_text_color,
      borderColor: props?.inputStyles?.input_text_border_color,
      labelColor: props?.inputStyles?.input_label_text_color,
    };
    const hasError = props.errorMessage || variant === 'error';
    const inputVariants = cn(
      'text-custom-base ',
      twMerge(
        variant &&
          `placeholder-hidden-onfocus border ps-4 pb-3 pt-6 peer block h-[52px] w-full appearance-none rounded-md focus:ring-0 shadow-none focus-visible:ring-0 focus-visible:outline-none`
      ),
      hasError ? '!border-red' : ''
    );
    const labelVariants = cn(
      `text-custom-xs peer-placeholder-shown:text-custom-base peer-focus:text-custom-xs peer-focus:-translate-y-2 peer-[:not(:placeholder-shown)]:-translate-y-2 `,
      twMerge(
        props.type !== 'checkbox' &&
          `absolute top-[18px] start-4 z-10 origin-[0] transform duration-500 `
      ),
      hasError ? '!text-red' : ''
    );
    const wrapDiv = (element: JSX.Element) => {
      return props.type !== 'checkbox' ? (
        <>{element}</>
      ) : (
        <div className={props.wrapperClassname}>{element}</div>
      );
    };
    if (props.isForm) {
      return (
        <div
          className={clsx(['relative rounded-md', props.containerClassname])}
        >
          {wrapDiv(
            <>
              {props.type === 'date' ? (
                <>
                  <Field
                    type={props.type}
                    readOnly={props.readOnly}
                    id={props.id}
                    as={props.as}
                    name={props.name}
                    className={clsx([props.inputClassname, inputVariants])}
                    placeholder={props.placeholder}
                    style={{
                      backgroundColor: styles.backgroundColor,
                      color: styles.color,
                      borderColor: styles.borderColor,
                      '--placeholder-color': styles.placeholder,
                    }}
                  >
                    {props?.as === 'select' ? props.options : null}
                  </Field>
                  <label
                    htmlFor={props.name}
                    className={clsx([props.labelClassname, labelVariants])}
                    style={{
                      color: styles.labelColor,
                    }}
                  >
                    {props.label}
                  </label>
                  {props?.placeholder && (
                    <label
                      htmlFor={props.name}
                      className={clsx([
                        'pointer-events-none absolute start-4 top-6 z-20 h-[21px] w-28 bg-white text-custom-base text-gray-dark',
                        'peer-placeholder-shown:block peer-focus:hidden peer-[:not(:placeholder-shown)]:hidden',
                      ])}
                      style={{
                        color: styles.placeholder,
                      }}
                    >
                      {props.placeholder}
                    </label>
                  )}
                </>
              ) : (
                <>
                  <Field
                    type={props.type}
                    readOnly={props.readOnly}
                    id={props.id}
                    as={props.as}
                    name={props.name}
                    className={clsx([props.inputClassname, inputVariants])}
                    placeholder={props.placeholder}
                    ref={ref}
                    style={{
                      backgroundColor: styles.backgroundColor,
                      color: styles.color,
                      borderColor: styles.borderColor,
                      '--placeholder-color': styles.placeholder,
                    }}
                  >
                    {props?.as === 'select' ? props.options : null}
                  </Field>
                  <label
                    htmlFor={props.name}
                    className={clsx([props.labelClassname, labelVariants])}
                    style={{
                      color: styles.labelColor,
                    }}
                  >
                    {props.label}
                  </label>
                </>
              )}
            </>
          )}
          {props.icon || null}
          <ErrorMessage
            name={props.name}
            component='h2'
            className='mt-1 text-custom-xs text-red'
          ></ErrorMessage>
        </div>
      );
    }
    if (props.type === 'tel') {
      return (
        <PhoneInput
          initialPhone={props.initialPhone}
          excludedCountries={props.excludedCountries}
          onChange={props.onChange}
          onClick={props.onClick}
          language={props.language}
          region={props.region}
        />
      );
    }

    return (
      <>
        <div
          onClick={() => {
            if (document && props.id) {
              document.getElementById(props.id)?.focus();
            }
          }}
          className={clsx([
            'relative',
            props.containerClassname,
            props.isPassword &&
              'flex h-[54px] items-center rounded-md border pe-4',
          ])}
        >
          {wrapDiv(
            <>
              {props.type === 'select' ? (
                <select
                  id={props.id}
                  name={props.name}
                  defaultValue={props.defaultValue}
                  className={clsx([props.inputClassname, inputVariants])}
                  onSelect={(e) => props.onChange(e)}
                  placeholder={props.placeholder}
                >
                  {props.options}
                </select>
              ) : (
                <input
                  type={props.type}
                  readOnly={props.readOnly}
                  id={props.id}
                  name={props.name}
                  value={props.value}
                  className={clsx([
                    props.inputClassname,
                    inputVariants,
                    props.isPassword && 'absolute',
                  ])}
                  disabled={props.disabled}
                  placeholder={props.placeholder}
                  onChange={(e) => props.onChange && props.onChange(e)}
                  ref={ref}
                  checked={props.checked}
                  autoComplete={props.autoComplete}
                  onBlur={props.onBlur}
                  style={
                    {
                      backgroundColor: styles.backgroundColor,
                      color: styles.color,
                      borderColor: styles.borderColor,
                      '--placeholder-color': styles.placeholder,
                    } as CustomCSSProperties
                  }
                />
              )}
              <label
                htmlFor={props.name}
                className={clsx([props.labelClassname, labelVariants])}
                style={{
                  color: styles.labelColor,
                }}
              >
                {props.label}
              </label>
              {props.readOnly && props.readOnlyLabel ? (
                <label className='absolute right-0 top-0 p-2 px-4 text-custom-xs text-gray rtl:left-0 rtl:right-auto'>
                  {props.readOnlyLabel}
                </label>
              ) : null}
            </>
          )}
          {props.icon || null}
        </div>
        {props.errorMessage && (
          <span className='mt-1 text-custom-xs text-red'>
            {props.errorMessage}
          </span>
        )}
      </>
    );
  }
);

Input.displayName = 'Input';

export default Input;
