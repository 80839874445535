'use client';

import { useEffect, useState } from 'react';
import { CloseIcon } from 'components/icon';
import clsx from 'clsx';
import { ModalStyleType, ModalType } from 'types/modal';
import ReactPortal from './react-portal';
import { twMerge } from 'tailwind-merge';
import MobileModal from 'components/mobile-modal';
import { useMediaQuery } from 'hooks/use-media-query';
import Image from 'next/image';

export default function SecondaryOptionModal({
  children,
  modalId,
  open,
  setOpen,
  title,
  hideCloseButton,
  modalWidth,
  backgroundColor,
  backgroundImage,
  titlePosition,
  contentClassName,
  titleContainerClassName,
  dynamicModalCloseHandler,
  reactPortalRemove = false,
  closeButtonImage,
  containerClassName,
  closeButtonClassName,
  modalClassname,
  backgroundBlurClassName,
  hideCloseButtonOnMobile,
  isPromoModal,
  titleClassName,
}: ModalType) {
  const matchesStatus = useMediaQuery('(min-width: 1024px)');
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (reactPortalRemove) {
      document.body.style.overflow = open ? 'hidden' : '';
    }
  }, [open, reactPortalRemove]);

  useEffect(() => {
    if (open) {
      setFadeIn(true);
    } else {
      setFadeIn(false);
    }
  }, [open]);

  function handleCloseModal() {
    if (setOpen) {
      setOpen(false);
    }
    if (dynamicModalCloseHandler) {
      dynamicModalCloseHandler();
    }
  }

  const CloseButton = () => (
    <Image
      src={closeButtonImage as string}
      alt='Close'
      height={20}
      width={20}
      className='h-5 w-5 cursor-pointer'
    />
  );

  const style: ModalStyleType = {};
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  if (backgroundImage) {
    style.backgroundImage = `url('${backgroundImage}')`;
  }

  const modalContent = () => {
    return (
      <>
        <div
          className={twMerge(
            'fixed left-0 right-0 top-0 z-[99] h-screen w-full bg-black/75 backdrop-blur-sm',
            backgroundBlurClassName
          )}
        ></div>

        <div
          className={twMerge(
            'fixed bottom-0 left-0 right-0 top-0 z-[99] h-dvh',
            'flex items-start justify-center overflow-y-auto',
            modalClassname,
            fadeIn ? 'opacity-100 transition-opacity duration-500' : 'opacity-0'
          )}
        >
          <div
            className={`${
              modalWidth ? modalWidth : 'sm:w-[440px]'
            } mx-auto my-6 flex h-full w-full items-center ${
              isPromoModal ? 'my-auto h-auto' : 'h-dvh py-6'
            }`}
          >
            <div
              className={twMerge(
                'relative w-full rounded-pop-up bg-white bg-cover p-8',
                contentClassName
              )}
              style={style}
            >
              {title && (
                <div
                  className={twMerge(
                    'mb-6 flex items-center justify-between',
                    titleContainerClassName
                  )}
                >
                  <span
                    className={`${
                      titlePosition ? 'w-full text-center' : ''
                    } text-xl`}
                  >
                    {title}
                  </span>
                  {!hideCloseButton && (
                    <button
                      onClick={() => handleCloseModal()}
                      className={clsx(['z-[99]', closeButtonClassName])}
                    >
                      {closeButtonImage ? (
                        <CloseButton />
                      ) : (
                        <CloseIcon className='h-5 w-5 cursor-pointer' />
                      )}
                    </button>
                  )}
                </div>
              )}

              {!title && !hideCloseButton && (
                <button
                  onClick={() => handleCloseModal()}
                  className={clsx(
                    'absolute end-8 z-[99]',
                    closeButtonClassName
                  )}
                >
                  {closeButtonImage ? (
                    <CloseButton />
                  ) : (
                    <CloseIcon className='h-5 w-5 cursor-pointer' />
                  )}
                </button>
              )}

              {children}
            </div>
          </div>
        </div>
      </>
    );
  };
  if (!open) return null;
  if (!matchesStatus) {
    if (!reactPortalRemove) {
      return (
        <ReactPortal wrapperId={modalId}>
          <MobileModal
            style='bottom'
            isOpen={open ?? false}
            setIsOpen={setOpen}
            title={title}
            dynamicModalCloseHandler={dynamicModalCloseHandler}
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
            closeButtonImage={closeButtonImage}
            containerClassName={containerClassName}
            contentClassName={contentClassName}
            closeButtonClassName={closeButtonClassName}
            backgroundBlurClassName={backgroundBlurClassName}
            hideCloseButtonOnMobile={hideCloseButtonOnMobile}
            titleContainerClassName={titleContainerClassName}
            titleClassName={titleClassName}
          >
            {children}
          </MobileModal>
        </ReactPortal>
      );
    } else {
      return (
        <MobileModal
          style='bottom'
          isOpen={open ?? false}
          setIsOpen={setOpen}
          title={title}
          dynamicModalCloseHandler={dynamicModalCloseHandler}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          closeButtonClassName={closeButtonClassName}
          contentClassName={contentClassName}
          containerClassName={containerClassName}
          backgroundBlurClassName={backgroundBlurClassName}
          hideCloseButtonOnMobile={hideCloseButtonOnMobile}
        >
          {children}
        </MobileModal>
      );
    }
  } else {
    if (!open) return null;
    if (!reactPortalRemove) {
      return <ReactPortal wrapperId={modalId}>{modalContent()}</ReactPortal>;
    } else {
      return <div>{modalContent()}</div>;
    }
  }
}
