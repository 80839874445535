'use client';

import i18n from 'i18next';
import { useEffect, useRef, useState } from 'react';
import {
  WarningIcon,
  CheckIcon,
  ChevronDownIcon,
  SaudiArabiaFlag,
  EmiratesFlagIcon,
} from 'components/icon';
import MobileModal from 'components/mobile-modal';
import { useTranslation } from 'app/i18n/client';
import { useMediaQuery } from 'hooks/use-media-query';
import { handleSignout } from 'utils/sign-out-handler';
import clsx from 'clsx';
import { clearBasketThunk } from 'redux/features/basket.reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { usePathname } from 'next/navigation';
import LoadingDots from 'components/loading-dots';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function ShippingCountry({
  iconColor,
  language,
  region,
}: {
  iconColor?: string;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const path = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const refDropDown = useRef(null);
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );
  const [temporaryRegion, setTemporaryRegion] = useState<string>();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isSwitching, setIsSwitching] = useState<RegionCodesType>();
  const isLoggedIn = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );
  useEffect(() => {
    setIsLoading(false);
  }, []);
  useEffect(() => {
    region && setTemporaryRegion(region);
  }, [region]);
  useEffect(() => {
    const closeOpenMenu = (event: any) => {
      if (
        refDropDown.current &&
        isOpen &&
        !(refDropDown.current as HTMLElement).contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', closeOpenMenu);
  });

  async function changeRegion(
    targetRegion: RegionCodesType,
    currentLanguage: LanguageCodesType,
    currentRegion: RegionCodesType,
    forceChangeMobile?: boolean
  ) {
    if (isSwitching != undefined) return;

    if (!isMobile || forceChangeMobile) {
      if (targetRegion === currentRegion) {
        return;
      }

      setIsSwitching(targetRegion);

      await dispatch(
        clearBasketThunk({ language: currentLanguage, region: currentRegion })
      );

      if (isLoggedIn) {
        handleSignout(false);
      }

      regionSwitch(targetRegion, currentLanguage);
      setIsSwitching(undefined);
    } else {
      setTemporaryRegion(targetRegion);
    }
  }

  const regionSwitch = (
    newRegion: RegionCodesType,
    currentLanguage: LanguageCodesType
  ) => {
    const newLng = `${currentLanguage}-${newRegion}`.toLowerCase();
    const segments = path.split('/');
    if (segments.length > 0) {
      segments[1] = newLng;
    }
    const updatedUrl = segments.join('/');
    i18n.changeLanguage(newLng);
    if (typeof window != 'undefined') {
      window.location.replace(updatedUrl);
    }
  };

  async function changeRegionMobile(
    currentLanguage: LanguageCodesType,
    currentRegion: RegionCodesType
  ) {
    changeRegion(
      (temporaryRegion ?? 'SA') as RegionCodesType,
      currentLanguage,
      currentRegion,
      true
    );
  }

  const content = () => {
    return (
      <div
        className={`${
          isOpen ? 'opacity-1 visible' : 'invisible opacity-0'
        } transition duration-300 ease-in-out max-xl:mt-6 lg:px-4 lg:py-4`}
      >
        <div className='hidden items-center justify-between lg:flex'>
          <span className='text-lg lg:text-custom-lg'>
            {t('shipping_country')}
          </span>
        </div>

        <div
          style={{ backgroundColor: 'rgb(255 237 203)' }}
          className='mb-2 mt-4 flex items-center space-x-6 rounded-lg p-[10px] lg:mb-0 rtl:space-x-reverse'
        >
          <WarningIcon style={{ position: 'absolute' }} />
          <span className='text-xs font-medium text-gray-dark'>
            {t('shipping_country_descripiton')}
          </span>
        </div>

        <div className='divide-y'>
          <div
            className='group flex cursor-pointer items-center justify-between py-4'
            onClick={() => {
              (async () => {
                await changeRegion('SA', language, region);
              })();
            }}
          >
            <div className='flex items-center space-x-3 rtl:space-x-reverse'>
              <SaudiArabiaFlag height='20px' width='20px' />
              <span
                className={`text-custom-base group-hover:font-semibold group-hover:text-blue ${
                  temporaryRegion == 'SA' ? 'font-semibold text-blue' : ''
                }`}
              >
                {t('Saudi Arabia')}
              </span>
            </div>

            {temporaryRegion == 'SA' && isSwitching != 'SA' && (
              <CheckIcon height='19px' />
            )}
            {isSwitching == 'SA' && (
              <LoadingDots className='bg-primary-blue-600' />
            )}
          </div>
          <div
            className='group flex cursor-pointer items-center justify-between py-4'
            onClick={() => {
              (async () => {
                await changeRegion('AE', language, region);
              })();
            }}
          >
            <div className='flex items-center space-x-3 rtl:space-x-reverse'>
              <EmiratesFlagIcon height='20px' width='20px' />
              <span
                className={`text-custom-base group-hover:font-semibold group-hover:text-blue ${
                  temporaryRegion == 'AE' ? 'font-semibold text-blue' : ''
                }`}
              >
                {t('United Arab Emirates')}
              </span>
            </div>
            {temporaryRegion == 'AE' && isSwitching != 'AE' && (
              <div>
                <CheckIcon height='19px' />
              </div>
            )}
            {isSwitching == 'AE' && (
              <LoadingDots className='bg-primary-blue-600' />
            )}
          </div>
        </div>
        <button
          className={clsx(
            'mt-2 w-full rounded-lg py-4 lg:hidden',
            temporaryRegion
              ? 'bg-blue text-white'
              : 'disabled bg-gray text-light-gray'
          )}
          onClick={() => {
            (async () => {
              await changeRegionMobile(language, region);
            })();
          }}
        >
          {t('update')}
        </button>
      </div>
    );
  };

  return (
    <>
      {isLoading ? null : (
        <div className='relative' ref={refDropDown}>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className='flex cursor-pointer items-center space-x-1 rtl:space-x-reverse'
          >
            {region === 'AE' ? (
              <EmiratesFlagIcon className='h-6 w-6' />
            ) : (
              <SaudiArabiaFlag className='h-6 w-6' />
            )}
            <ChevronDownIcon
              className={`lgw-9 h-6 w-6 text-gray lg:h-4 lg:text-white`}
              style={{ color: iconColor }}
            />
          </div>

          <div className='hidden lg:block'>
            <div
              className={`${
                isOpen ? 'opacity-1 visible' : 'invisible opacity-0'
              } absolute -end-[10px] top-[30px] z-[99] w-[300px] rounded-lg bg-white shadow-2xl transition duration-200 ease-in-out`}
            >
              <div className='absolute -top-2 inline-block w-6 overflow-hidden ltr:right-[3px] rtl:left-[12px]'>
                <div className='h-2 w-2 origin-bottom-left rotate-45 transform bg-white rtl:rotate-[225deg]'></div>
              </div>
              {content()}
            </div>
          </div>

          <MobileModal
            style='bottom'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={ts('shipping_country')}
          >
            {content()}
          </MobileModal>
        </div>
      )}
    </>
  );
}
