import { useMemo, useState, useEffect } from 'react';
import { cn } from 'utils/cn.util';
import { useTranslation } from 'app/i18n/client';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { countries } from 'countries-list';
import { regions } from 'app/i18n/settings';

// Components
import { CheckIcon, CircularCancel, DropdownArrowIcon } from 'components/icon';

import type { LanguageCodesType, RegionCodesType } from 'utils';
import type { ICountry, TCountryCode } from 'countries-list';

import 'country-flag-icons/1x1/flags.css';

export type PhoneNumber = {
  countryCode: TCountryCode;
  callingCode: string;
  phoneNumber: string;
  fullNumber: string;
};

export function PhoneInput({
  initialPhone,
  excludedCountries,
  onChange,
  onClick,
  language,
  region,
}: {
  initialPhone?: PhoneNumber;
  excludedCountries?: string[];
  onChange: (payload: PhoneNumber) => void;
  onClick?: () => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'login'
  );

  const [selectedCountry, setSelectedCountry] = useState<ICountry>(
    countries[region]
  );
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<TCountryCode>(region);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [isOpenCountryDropdown, setIsOpenCountryDropdown] =
    useState<boolean>(false);

  useEffect(() => {
    if (initialPhone) {
      if (initialPhone.countryCode && countries[initialPhone.countryCode]) {
        setSelectedCountry(countries[initialPhone.countryCode]);
        setSelectedCountryCode(initialPhone.countryCode);
      }

      setPhoneValue(initialPhone.phoneNumber);
    }
  }, [initialPhone]);

  const countriesOrdered: Record<TCountryCode, ICountry> = useMemo(() => {
    const priorityRegions = regions.filter(
      (region) => !excludedCountries?.includes(region)
    );
    const restOfTheRegions = Object.entries(countries)
      .filter(
        ([code]) =>
          !priorityRegions.includes(code as RegionCodesType) &&
          !excludedCountries?.includes(code)
      )
      .sort((a, b) => a[1].name.localeCompare(b[1].name));

    return {
      ...Object.fromEntries(
        priorityRegions.map((region) => [region, countries[region]])
      ),
      ...Object.fromEntries(restOfTheRegions),
    } as Record<TCountryCode, ICountry>;
  }, [excludedCountries]);

  useEffect(() => {
    onChange?.({
      countryCode: selectedCountryCode,
      callingCode: selectedCountry?.phone?.[0]?.toString() || '',
      phoneNumber: phoneValue,
      fullNumber: `+${selectedCountry.phone[0]}${phoneValue}`,
    } as PhoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, phoneValue]);

  return (
    <>
      <div className='relative mt-3 flex' dir='ltr'>
        <div className='absolute start-4 top-3 w-28'>
          <div
            onClick={() => setIsOpenCountryDropdown((value) => !value)}
            className='flex cursor-pointer items-center'
          >
            <div
              className={cn(
                'rounded-full text-custom-flag',
                `flag:${selectedCountryCode}`
              )}
            ></div>
            <span className='mx-2 text-custom-base'>
              +{selectedCountry.phone[0]}
            </span>
            <span className='flex items-center'>
              <DropdownArrowIcon
                className={cn(
                  isOpenCountryDropdown
                    ? 'rotate-0 fill-blue'
                    : 'rotate-180 fill-black'
                )}
              />
            </span>
            <div className='ms-4 h-7 border-l border-light-gray-300'></div>
          </div>
        </div>
        <input
          id='phoneValue'
          className={cn(
            'placeholder-text-light-gray h-[52px] w-full rounded-lg pe-2 ps-[142px] text-custom-base shadow-none outline-none focus:ring-0 focus-visible:ring-0',
            'border-[1px] border-light-gray bg-white focus:border-blue'
          )}
          placeholder='i.e: 501918660'
          type='tel'
          autoComplete='off'
          name='phone'
          value={phoneValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPhoneValue(e.target.value)
          }
          onClick={onClick}
        />
        {phoneValue?.length > 0 && (
          <CircularCancel
            className='absolute end-4 top-[18px]'
            onClick={() => setPhoneValue('')}
          />
        )}
      </div>

      {/* Dropdown */}
      <div
        className={`${
          isOpenCountryDropdown ? 'block' : 'hidden'
        } absolute z-[3] w-[378px] rounded-lg rounded-b-none bg-white shadow-2xl`}
      >
        <div className='absolute -top-2 inline-block overflow-hidden ltr:left-20 rtl:right-10'>
          <div className='h-[7px] w-[14px] origin-bottom-left rotate-45 transform bg-white'></div>
        </div>

        <div className='max-h-[195px] divide-y overflow-y-auto' dir='ltr'>
          {Object.keys(countriesOrdered).map((countryKey) => (
            <div
              key={countryKey}
              className='group flex cursor-pointer items-center justify-between px-3 py-4 hover:bg-[#F4F8FA]'
              onClick={() => {
                setSelectedCountry(
                  countriesOrdered[countryKey as TCountryCode]
                );
                setSelectedCountryCode(countryKey as TCountryCode);
                setIsOpenCountryDropdown(false);
              }}
            >
              <div className='flex items-center space-x-2'>
                <div
                  className={cn(
                    'rounded-full text-custom-flag',
                    `flag:${countryKey}`
                  )}
                ></div>

                <span className='text-custom-sm group-hover:text-primary-blue'>
                  {t(countriesOrdered[countryKey as TCountryCode].name)} +
                  {countriesOrdered[countryKey as TCountryCode].phone[0]}
                </span>
              </div>
              {countriesOrdered[countryKey as TCountryCode].native ===
                selectedCountry.native && <CheckIcon />}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
